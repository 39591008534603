import * as React from 'react'
import * as typeformEmbed from '@typeform/embed'
import { getReferrer, getSearchValue } from '../utils/referrers'

interface Props {
  url: string
}

export class TypeformInner extends React.Component<Props> {
  el = null

  componentDidMount() {
    if (this.el) typeformEmbed.makeWidget(this.el, this.props.url, {})
  }

  render() {
    return <div ref={el => (this.el = el)} style={{ width: '100%', height: '100%' }} />
  }
}

export const Typeform: React.FC<Props> = props => {
  const [state, setState] = React.useState<{ referrer: string; search?: string } | null>(null)

  React.useEffect(() => {
    const referrer = getReferrer() || ''
    const search = getSearchValue() || ''

    setState({ referrer, search })
  }, [])

  if (!state) return null

  const search = window.location.search || state.search
  const url = `${props.url}${search ? `${search}&` : '?'}referrer=${state.referrer}`

  return <TypeformInner url={url} />
}
